<template>
  <div><router-view /></div>
</template>

<style>
body {
  font-family: Roboto;
  color: #1e1e1e;
}
</style>
